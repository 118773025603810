import * as React from 'react'
import Layout from './layout'
import { graphql, Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import Seo from './seo'
import Helmet from 'react-helmet'

const PostArticle = ({ data }) => {
  const post = data.markdownRemark
  const { previous, next } = data

  return (
    <>
      <Seo
        pageTitle={post.frontmatter.title}
        pageDescription={post.excerpt}
        pageLocation={data.markdownRemark.fields.slug}
      />
      <Helmet>
        <meta name='date' content={post.frontmatter.date} />
      </Helmet>
      <Layout>
        <article className='container-fluid'>
          <div className='row justify-content-center py-5'>
            <div className='col-md-5'>
              <h1>{post.frontmatter.title}</h1>
              <h6>{post.frontmatter.date}</h6>
              <div dangerouslySetInnerHTML={{ __html: post.html }}></div>

              <div id='postNav' className='row my-5 justify-content-between'>
                <div className='col-6 text-start'>
                  {previous && (
                    <div className='container align-middle'>
                      <Link to={previous.fields.slug}>
                        <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>{' '}
                        {previous.frontmatter.title}
                      </Link>
                    </div>
                  )}
                </div>

                <div className='col-6 text-end'>
                  {next && (
                    <div className='container align-middle'>
                      <Link to={next.fields.slug}>
                        {next.frontmatter.title}{' '}
                        <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </article>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query postBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 300)
      html
      frontmatter {
        title
        date(formatString: "dddd DD MMMM YYYY")
      }
      fields {
        slug
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`

export default PostArticle
